const ActionTypes={
    FETCH_PRODUCTS:"FETCH_PRODUCTS",
    FETCH_SINGLE_PRODUCT:"FETCH_SINGLE_PRODUCT",
    FETCH_CATEGORIES:"FETCH_CATEGORIES",
    FETCH_SINGLE_CATEGORY:"FETCH_SINGLE_CATEGORY",
    FETCH_TAGS:"FETCH_TAGS",
    FETCH_PP_PRODUCTION:"FETCH_PP_PRODUCTION",
    FTECH_PP_BIPRODUCTS:"FTECH_PP_BIPRODUCTS",
    PRODUCTS_FILTER:"PRODUCTS_FILTER"
};

export default ActionTypes;