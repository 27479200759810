import React from 'react'
import './exportEvents.css'

export default function ExportEvents() {
  return (
    <>
      <h1>Export Events</h1>
    </>
  )
}
