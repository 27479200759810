import React from 'react'
import './myEduMainComp.css'
import ServicesNavbar from '../../ServicesNavbar/ServicesNavbar.js'
import SideNavBar from '../../sideNavBar/sideNavBar'

export default function MyEduMainComp() {
  const navData = [
    {
      name: 'Profile',
      icon: 'fa fa-address-card',
      path: '',
    },
    {
      name: 'Live Classes',
      icon: 'fa fa-plus-circle',
      path: '',
    },
    {
      name: 'Practicals',
      icon: 'fa fa-address-card',
      path: '',
    },
    {
      name: 'Materials',
      icon: 'fa fa-address-card',
      path: '',
    },
    {
      name: 'Connect to FEED',
      icon: 'fa fa-address-card',
      path: '',
    },
  ]
  return (
    <>
      <ServicesNavbar />
      <div className="row m-0">
        <SideNavBar
          className="col"
          data={navData}
          bgColor="#ef676d"
          textColor="white"
        ></SideNavBar>
      </div>
    </>
  )
}
