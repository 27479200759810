import React from 'react'
import './startExport.css'

export default function StartExport() {
  return (
    <>
      <h1>Start Export</h1>
    </>
  )
}
