import React from 'react'
import './myBusinessHome.css'
import { Grid, Button } from '@material-ui/core'

export default function MyBusinessHome() {
  return (
    <>
      <div className="d-lg-flex mybusiness-wrap">
        <div className="fpo-about">
          <div className="text-center">
            <img
              src={`${process.env.PUBLIC_URL}/assets/my-business-icon.jpg`}
              width="100"
              alt=""
              style={{ borderRadius: '50px 50px' }}
            />
          </div>
          <div className="text-center">
            <h3>MY BUSINESS</h3>
          </div>
          <div className="text-center">
            <img
              src={`${process.env.PUBLIC_URL}/assets/ant-small.png`}
              alt=""
            />
          </div>
        </div>
        <div className="mybusi-cards-wrap">
          <div className="row m-auto text-center">
            <div className="col-lg-4">
              <img
                src={`${process.env.PUBLIC_URL}/assets/my-business/business-profile.png`}
                // width="70"
                alt=""
              />
            </div>
            <div className="col-lg-4">
              <img
                src={`${process.env.PUBLIC_URL}/assets/my-business/business-account.png`}
                // width="70"
                alt=""
              />
            </div>
            <div className="col-lg-4">
              <img
                src={`${process.env.PUBLIC_URL}/assets/my-business/compliance-registration.png`}
                // width="70"
                alt=""
              />
            </div>
            <div className="col-lg-4">
              <img
                src={`${process.env.PUBLIC_URL}/assets/my-business/business-plan.png`}
                // width="70"
                alt=""
              />
            </div>
            <div className="col-lg-4">
              <img
                src={`${process.env.PUBLIC_URL}/assets/my-business/business-loan-schemes.png`}
                // width="70"
                alt=""
              />
            </div>
            <div className="col-lg-4">
              <img
                src={`${process.env.PUBLIC_URL}/assets/my-business/marketing-support.png`}
                // width="70"
                alt=""
              />
            </div>
          </div>
          <Grid container justify="center">
            <Button className="business-feed-Connect-btn" variant="contained">
              <b>Connect FEED </b>
            </Button>
          </Grid>
        </div>
      </div>
    </>
  )
}
