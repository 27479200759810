import React,{ useState, useEffect} from 'react';
import './Login.css';
// import HOLoginComponent from '../../checkLogin';
import axiosInstance from '../../axios';
import qs from 'qs';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
function Login() {
  const [submitLogin,setSubmitLogin]=useState('');
  const history=useHistory();
  useEffect(()=>{
    const validateLogin=async ()=>{
      try{
        let identity=document.querySelector(".identity").value;
        let password=document.querySelector(".password").value;
        let serializedStr=qs.stringify({
          identity:identity,
          password:password
        });
        let response=await axiosInstance.post('/fpo/member/login',serializedStr);
        document.querySelector(".loginbtn").innerText='Login';
        let APIResponse=response.data;
        if(APIResponse.status === 1)
        {
          document.querySelector(".loginform").reset();
          let token=APIResponse.data.token;
          setSubmitLogin('');
          localStorage.setItem("token",token);
          history.replace('/myfpo');
          window.location.reload();
        }
        else if(APIResponse.status === 0)
        {
          setSubmitLogin('');
          swal("Error",`${APIResponse.message}`,"error");
        }
      }
      catch(e){
        console.log(e);
      }
    };
    if(submitLogin !== ''){
      validateLogin();
    }
  },[submitLogin,history]);
  return (
    <>
      <div className="container-fluid fpo-login">
        <div className="row login-row">
          <div className="col-lg-6 m-auto login-section">
            <div className="login-header shadow-sm d-flex align-items-center justify-content-center">
              <h5 className="text-center text-white">FPO Member Login</h5>
            </div>
            <form onSubmit={(e)=>{
              e.preventDefault();
              setSubmitLogin(true);
              document.querySelector(".loginbtn").innerText="Logging In...";
            }} className="loginform">
              <div className="form-group mb-4">
                <input
                  type="text"
                  className="form-control identity"
                  name="identity"
                  placeholder="Email Id / Membership Id"
                />
              </div>
              <div className="form-group mb-4">
                <input
                  type="password"
                  className="form-control password"
                  name="password"
                  placeholder="Password"
                />
              </div>
              <div className="form-group text-center">
                <button type="submit" className="loginbtn">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login;
