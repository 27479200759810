import React from 'react'
import './myProductsMainComp.css'
import ServicesNavbar from '../../ServicesNavbar/ServicesNavbar.js'
import SideNavBar from '../../sideNavBar/sideNavBar'

export default function MyProductsMainComp() {
  const navData = [
    {
      name: 'Expand All',
      icon: 'fa fa-plus-circle',
      path: '',
    },
    {
      name: 'Summary',
      icon: 'fa fa-address-card',
      path: '',
    },
    {
      name: 'Production',
      icon: 'fa fa-address-card',
      path: '',
    },
    {
      name: 'Trade',
      icon: 'fa fa-address-card',
      path: '',
    },
    {
      name: `Untapped Market`,
      icon: 'fa fa-address-card',
      path: '',
    },
    {
      name: 'By Products',
      icon: 'fa fa-address-card',
      path: '',
    },
    {
      name: 'Processing',
      icon: 'fa fa-address-card',
      path: '',
    },
    {
      name: 'Data Base & Docs',
      icon: 'fa fa-address-card',
      path: '',
    },
    {
      name: 'Schemes & Policies',
      icon: 'fa fa-address-card',
      path: '',
    },
    {
      name: 'Product Guide',
      icon: 'fa fa-address-card',
      path: '',
    },
    {
      name: 'FEED Foot Print',
      icon: 'fa fa-address-card',
      path: '',
    },
  ]
  return (
    <>
      <ServicesNavbar />
      <div className="row m-0">
        <SideNavBar
          className="col"
          data={navData}
          bgColor="#005e9e"
          textColor="white"
        ></SideNavBar>
      </div>
    </>
  )
}
