import React,{ useState,useEffect } from 'react'
import axiosInstance from '../../axios';
import swal from 'sweetalert';
import qs from 'qs';
import './Register.css';
function FPOMember() {
  const [formdata,setFormData]=useState();
  const [sendOTPFlag,setSendOTPFlag]=useState();
  const [validateOTPFlag,setValidateOTPFlag]=useState();
  const makePayment=(paymentToken)=>{
    window.Layer.checkout({
        token: paymentToken,
        //Production
        // accesskey: "c166de80-a734-11eb-94c6-1342ff875bf1"
        //Test
        accesskey: "c166de80-a734-11eb-94c6-1342ff875bf1"
    },
    function(response) {
    
        if (response.status === "captured") {
                        
          // response.payment_token_id
          // response.payment_id
          console.log(response);
          swal("Good Job!","You have successfully registered as FPO Member!","success");

        } else if (response.status === "created") {
            console.log(response);
    
        } else if (response.status === "pending") {
            console.log(response);

        } else if (response.status === "failed") {
            console.log(response);

        } else if (response.status === "cancelled") {
            console.log(response);
        }
    },
    function(err) {
        //integration errors
        console.log(err);
    }
 );
};
  useEffect(()=>{
    async function handleRegistration(){
      try{
        let response=await axiosInstance.post('/fpo/member/register',formdata);
        console.log(response.data.message);
        document.querySelector(".regbtn").innerText='Register';
        setFormData('');
        let APIResponse=response.data;
        if(APIResponse.status === 1)
        {
          document.querySelector(".reg-form").reset();
          let pmtInfo=APIResponse.data.payment_data;
          // console.log(pmtInfo);
          document.querySelector(".reg-cover").style.display="none";
          makePayment(pmtInfo.id);
        }
        else if(APIResponse.status === 2)
        {
          document.querySelector(".otp-overlay").style.display="flex";
          let mobno=document.getElementById("mobileNo").value;
          document.getElementById("mobno").value=mobno;
          // swal("Alert!",`${response.data.message}`,"warning");
        }
        else if(APIResponse.status === 0)
        {
          swal("Error",`${response.data.message}`,"error");
        }
      }
      catch(e){
        console.log(e);
      }
    }
    if(formdata !== undefined && formdata !== '')
    {
      handleRegistration();
    } 
  },[formdata]);
  useEffect(()=>{
    async function sendOTP(){
      try{
        let mobno=document.getElementById("mobileNo").value;
        let serializedStr=qs.stringify({
          mobileNo:mobno
        });
        let response=await axiosInstance.post('/otp/send',serializedStr);
        console.log(response.data.message);
        document.querySelector(".sendotp").innerText='Send OTP';
        if(response.data.status === 1)
        {
          document.querySelector("#sendotp-form").reset();
          document.getElementById("sendotp-form").style.display="none";
          document.getElementById("validateotp-form").style.display="block";
          document.getElementById("verifyMobNo").value=mobno;
          setSendOTPFlag('');
          swal("Good Job!",`${response.data.message}`,"success");
        }
        else if(response.data.status === 0)
        {
          swal("Error",`${response.data.message}`,"error");
        }
      }
      catch(e){
        console.log(e);
      }
    } 
    if(sendOTPFlag === true)
    {
      sendOTP();
    }
  },[sendOTPFlag]);
  useEffect(()=>{
    async function validateOTP(){
      try{
        let mobno=document.getElementById("mobileNo").value;
        let otp=document.getElementById("otp").value;
        let serializedStr=qs.stringify({
          mobileNo:mobno,
          otp:otp
        });
        let response=await axiosInstance.post('/otp/validate',serializedStr);
        console.log(response.data.message);
        document.querySelector(".verifybtn").innerText='Verify';
        if(response.data.status === 1)
        {
          document.querySelector("#validateotp-form").reset();
          document.querySelector(".otp-overlay").style.display="none";
          setValidateOTPFlag('');
          document.querySelector(".regbtn").click();
          document.querySelector(".reg-cover").style.display="flex";
          swal("Good Job!",`${response.data.message}`,"success");
        }
        else if(response.data.status === 0)
        {
          swal("Error",`${response.data.message}`,"error");
        }
      }
      catch(e){
        console.log(e);
      }
    }
    if(validateOTPFlag === true)
    {
      validateOTP();
    }
  },[validateOTPFlag]);
  return (
    <>
      <div className="container-fluid fpo-register">
        <div className="row">
          {/* OTP */}
          <div className="otp-overlay">
              <div className="otp-popup">
                  {/* Send OTP*/}
                  <form id="sendotp-form" onSubmit={(e)=>{
                      e.preventDefault();
                      setSendOTPFlag(true);
                      document.querySelector(".sendotp").innerText='Sending...';
                  }}>
                     <div className="form-group mb-3">
                        <input type="text" name="mobileNo" id="mobno" className="form-control" placeholder="Mobile No." pattern="[0-9]{10}" title="Mobile No. should contain 10 digits only. Ex: 9999999999"/>
                     </div>
                     <div className="form-group text-center">
                        <button type="submit" className="sendotp">Send OTP</button>
                     </div>
                  </form>
                  {/* Validate OTP */}
                  <form id="validateotp-form" onSubmit={(e)=>{
                      e.preventDefault();
                      setValidateOTPFlag(true);
                      document.querySelector(".verifybtn").innerText='Verifying...';
                  }}>
                      <input type="hidden" name="mobileNo" id="verifyMobNo"/>
                     <div className="form-group mb-3">
                        <input type="text" name="otp" id="otp" className="form-control" placeholder="OTP" pattern="[0-9]{5}" title="OTP should contain 5 digits only. Ex: 55555"/>
                     </div>
                     <div className="form-group text-center">
                        <button type="submit" className="verifybtn">Verify</button>
                     </div>
                  </form>
              </div>
          </div>
          <div className="col-lg-6 m-auto shadow-lg register-section">
            <div className="reg-cover">
                <h3 className="text-center">Submitting your data&nbsp;<span className="spinner-border spinner-border-md"></span></h3>
            </div>
            <div className="reg-header shadow-sm d-flex align-items-center justify-content-center">
              <h5 className="text-center text-white">
                FPO Member Registration
              </h5>
            </div>
            <form onSubmit={(e)=>{
                e.preventDefault();
                let form=e.target;
                let formInfo=new FormData(form);
                let profilePic=document.getElementById("profilePic").files[0];
                formInfo.append('profilePic',profilePic);
                document.querySelector(".regbtn").innerText='Registering...';
                setFormData(formInfo);
            }} className="reg-form">
              <div className="form-row mb-4">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Your Name"
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    id="mobileNo"
                    className="form-control"
                    name="mobileNo"
                    placeholder="Phone No."
                  required/>
                </div>
              </div>
              <div className="form-row mb-4">
                <div className="col">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Email"
                  />
                </div>
                <div className="col">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    placeholder="Password"
                  />
                </div>
              </div>
              <div className="form-group mb-4">
                <input
                  type="text"
                  className="form-control"
                  name="fpoName"
                  placeholder="FPO Name"
                />
              </div>
              <div className="form-row mb-4">
                <div className="col">
                  <select className="form-control" name="state">
                    <option value="">Select State</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                  </select>
                </div>
                <div className="col">
                  <select className="form-control" name="district">
                    <option value="">Select District</option>
                    <option value="Krishna">Krishna</option>
                  </select>
                </div>
              </div>
              <div className="form-row mb-4">
                <div className="col">
                  <select className="form-control" name="mandal">
                    <option value="">Select Mandal</option>
                    <option value="Urban">Urban</option>
                  </select>
                </div>
                <div className="col">
                  <select className="form-control" name="village">
                    <option value="">Select Village</option>
                    <option value="Prasadampadu">Prasadampadu</option>
                  </select>
                </div>
              </div>
              <div className="form-group mb-4">
                <select className="form-control" name="designation">
                  <option value="">Select Designation</option>
                  <option value="Student">Student</option>
                </select>
              </div>
              <div className="form-group mb-4">
                <textarea
                  name="address"
                  placeholder="Address..."
                  className="form-control"
                ></textarea>
              </div>
              <input type="hidden" name="roleId" value="R52020"/>
              <div className="form-group mb-2">
                <label for="profilePic"><b>Upload your Profile photo</b></label>
              </div>
              <input type="file" id="profilePic"/>
              <div className="form-group text-center">
                <button type="submit" className="regbtn">
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default FPOMember
